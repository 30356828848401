import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Badges from "components/UI/Badges/Badges";

import { MARKET_MODE_STATUS } from "models/market";

import "./SiteLinks.scss";
import Icon from "components/UI/IconSVG/Icon";

export const SiteLinks = observer(({ links, onClick }) => {
  return (
    <div className="dropdown-links__services">
      <p className="dropdown-links__title">Популярные услуги</p>
      {links.map((link, index) => (
        <div className="dropdown-links__services-item" key={index}>
          <nav className="dropdown-links__services-item-link">
            <div className="dropdown-links__services-item-img">
              <Icon className="dropdown-links__services-item-img-logo" name={link.logo} />
              <Icon className="dropdown-links__services-item-img-icon" sprite="activities" name={link.icon} />
            </div>
            <Link to={link.href} onClick={() => { onClick(link) }}>{link.label}</Link>
          </nav>
          {link.status && link.status !== MARKET_MODE_STATUS.REGULAR && (
            <Badges status={link.status} />
          )}
        </div>
      ))}
    </div>
  );
});
