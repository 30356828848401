import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import Button from "components/UI/Button/Button.jsx";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon.jsx";
import { ActivitiesCalculator } from "../componentsCalculatorStep/ActivitiesCalculator/ActivitiesCalculator.jsx";
import ModeSelection from "../componentsCalculatorStep/ModeSelection/ModeSelection.jsx";
import { BudgetSlider } from "components/BudgetSlider/BudgetSlider.jsx";
import Deadline from "../componentsCalculatorStep/Deadline/Deadline.jsx";
import Faqed from "components/UI/Faqed/Faqed.jsx";
import Recommendation from "../componentsCalculatorStep/Recommendation/Recommendation.jsx";

import popupStore from "components/Popup/PopupStore.js";
import { getCountActions, getCountDays } from "utils/helpers.js";

import upDoubleArrow from 'assets/images/upDoubleArrow.svg';

import "./IsMobileCalculatorStep.scss";
import Icon from "components/UI/IconSVG/Icon.jsx";

export const IsMobileCalculatorStep = observer(
  ({
    store,
    calculator,
    pathname,
    reviewRef,
    commentRef,
    getDisplayCount,
    getAllActionCount,
    onClickNext,
    showRecommendation,
  }) => {
    const [active, setActive] = useState(false);
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);
    const { ref, inView } = useInView({ threshold: 0.2 });
    const { market, mode } = calculator;

    const subtitle = market.isSeo
      ? `Как получить эффект SEO ${market.title}`
      : "Как получить эффект от ПФ";

    const handleShowRecommendation = () => {
      setShowRecommendationModal(true);
      popupStore.open('recommendation-modal');
    }

    useEffect(() => {
      if (inView) {
        setActive(true);
      } else {
        setActive(false);
      }
    }, [inView])

    return (
      <div className="market-step2-mobile">
        {store.calculators.length > 1 && <ModeSelection store={store} />}
        <div className="market-step2-mobile__distribution">
          <Icon className="market-step2-mobile__distribution-icon" name={market.icon} />
          <Faqed text={mode.stepTwoTitleFaq}>
            <h3 className="market-step2-mobile__distribution-title">
              {mode.calculatorTitle}
            </h3>
          </Faqed>
        </div>
        {showRecommendation && 
          <div className="market-step2-mobile__recommendation">
            <p className="market-step2-mobile__recommendation-title">Рекомендации</p>
            <div className="market-step2-mobile__recommendation-description">
              <p className="market-step2-mobile__recommendation-subtitle">{subtitle}</p>
              <Button title="Читать" className="market-step2-mobile__recommendation-button" onClick={handleShowRecommendation}/>
            </div>
            {showRecommendationModal && <Recommendation market={market} />}
          </div>
        }
        <div ref={ref}>
          <ActivitiesCalculator
            store={store}
            reviewRef={reviewRef}
            commentRef={commentRef}
          />
        </div>

        <div className={`market-step2-mobile__settings ${active ? "fixed-bottom" : ""}`}>
          <div className="market-step2-mobile__budget-slider-container">
            <div className="market-step2-mobile__budget-slider-action">
              <BudgetSlider store={store} onClickNext={onClickNext}>
                <p className="market-step2-mobile__project-price-info">
                  {`${store.currentCalculator.price} ₽`}
                </p>
                <div className="market-step2-mobile__project-info">
                  <div className="market-step2-mobile__project-info_action">
                    <p>≈</p>
                    <Icon className="market-step2-mobile__project-info_action-icon" sprite="activities" name="action" color="#4F4F4F" />
                    <p>{`${getAllActionCount(store.currentCalculator, pathname)} ${getCountActions(getAllActionCount(store.currentCalculator, pathname))}`}</p>
                  </div>
                  {getDisplayCount(store.currentCalculator, pathname) && (
                    <div className={`market-step2-mobile__project-info_reviews ${mode.actions.find((action) =>action.type.includes("REVIEW")) ? "": "hidden"}`}>
                      <p>≈</p>
                      <Icon className="market-step2-mobile__project-info_reviews-icon" sprite="activities" name="message" color="#4F4F4F" />
                      <p>{getDisplayCount(store.currentCalculator, pathname)}</p>
                    </div>
                  )}
                </div>
              </BudgetSlider>
            </div>
          </div>
        </div>
        <div className="market-step2-mobile__deadline">
          <p className="market-step2-mobile__deadline-title">
            Выберите скорость отправки заданий исполнителям:
          </p>
          <div className="market-step2-mobile__deadline-choice">
            {store.currentCalculator.deadlines.map((deadline, i) => (
              <Deadline
                key={i}
                deadline={`${deadline.days} ${getCountDays(deadline.days)}`}
                title={deadline.title}
                isActive={i === store.selectedDeadlineIndex}
                onClick={() => {
                  store.selectDeadline(i);
                }}
              />
            ))}
          </div>
          <ButtonWithIcon
              className="market-step2__create-button"
              title="Следующий шаг"
              icon={upDoubleArrow}
              onClick={onClickNext}
            />
        </div>
      </div>
    );
  }
);
